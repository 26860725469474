<template>
  <div class="error-wrapper" :class="theme">
    <div>
      <div class="title-wrapper">
        <div class="error">
          <span class="title">Login fehlgeschlagen</span>
        </div>
      </div>
    </div>
    <div v-html="message" class="message"></div>
    <div class="button-login">
      <p>
        <Button text="Erneut Einloggen" href="#" v-on:click.native="retry" />
      </p>
    </div>
  </div>
</template>
<script>
import Button from "./Button";

export default {
  name: "LoginError",
  components: {
    Button,
  },
  props: {
    message: String,
    theme: String,
  },
  methods: {
    retry() {
      this.$emit("errorMessage", null);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/colors";
#frn-loginbox {
  div {
    color: $frn-white;
  }

  .error-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px;
  }

  .title-wrapper {
    height: 30px;
    .title {
      padding-left: 10px;
      line-height: 31px;
      font-size: 22px;
    }
  }
  .error {
    display: flex;
    align-items: center;
    position: relative;
    &::before {
      content: "";
      width: 24px;
      height: 21px;
      background-image: url("https://components.freenet.de/assets/Login/Error.svg");
      background-repeat: no-repeat;
    }
    span.title {
      padding-top: 0 !important;
    }
  }
  .message {
    overflow-y: auto;
    max-height: 130px;
    font-size: 14px;
    margin-top: 20px;
  }
  .frn-white {
    .title,
    .message {
      color: $black-text !important;
      >>> a.error_link {
        color: $black-text !important;
      }
    }
    .error {
      &::before {
        background-image: url("https://components.freenet.de/assets/Login/Error_black.svg");
      }
    }
  }
  .button-login {
    margin-top: 20px;
    p {
      margin: 0;
    }
  }
}
</style>
<style lang="scss">
#frn-loginbox {
  .frn-white {
    .message {
      a.error_link {
        color: #000 !important;
      }
    }
  }
}
</style>
