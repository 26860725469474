<template>
  <div class="maintenance" :class="theme">
    <div
      class="maintenance-wrapper"
      :class="maintenanceMessage.type == 'maintenance' ? 'blue' : 'red'"
    >
      <div class="maintenance-body">
        <div>
          <img src="@/assets/Login/Maintenance.png" />
        </div>
        <p>{{ getTitle }} - {{ maintenanceMessage.title }}</p>
      </div>
    </div>
    <div class="message" style="font-size: 14px; padding: 10px">
      {{ maintenanceMessage.message }}
    </div>
  </div>
</template>
<script>
export default {
  name: "Maintenance",
  props: {
    maintenanceMessage: Object,
    theme: String,
    world: String,
  },
  computed: {
    getTitle() {
      let text = "freenet Mail";
      switch (this.world) {
        case "5":
          text = "DEVK Datentresor";
          break;
        case "4":
          text = "mobilcom-debitel cloud";
          break;
      }
      return text;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/colors";

#frn-loginbox {
  div {
    color: $frn-white;
  }
  .maintenance {
    .maintenance-wrapper {
      padding: 13px 10px;
    }
    .maintenance-body {
      min-height: 30px;
      display: flex;
      img {
        padding-top: 4px;
      }
      p {
        padding-left: 10px;
        line-height: 31px;
        font-size: 22px;
        margin: 0;
      }
    }
    .red {
      background-color: $frn-red;
    }

    .blue {
      background-color: $frn-dark-blue;
    }

    .frn-white {
      .message {
        color: $black-text !important;
      }
    }
  }
}
</style>
