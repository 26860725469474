<template>
  <div :class="theme">
    <template v-if="frnportalnoindex">
      <div class="frn_subPage">
        <Button
          text="Kostenlose E-Mail sichern"
          href="https://email.freenet.de/registrierung/frn?epid=e9900000057&tid=2001004"
          target="_top"
        />
        <Button
          text="Login"
          href="https://www.freenet.de/index.html"
          target="_top"
        />
      </div>
    </template>
    <template v-else>
      <div class="login-main" :class="'world_' + world">
        <div v-if="!blank" class="login-logo" :class="theme">
          <p>{{ getTitle }}</p>
        </div>

        <div class="form">
          <input
            :class="{ hasError: $v.loginname.$error }"
            type="text"
            @keyup.enter="doLogin"
            v-model="propLoginname"
            @change="$emit('update:loginname', propLoginname)"
            placeholder="E-Mail-Adresse"
            ref="login"
          />
          <input
            :class="{ hasError: $v.password.$error }"
            type="password"
            @keyup.enter="doLogin"
            @change="$emit('update:password', propPassword)"
            v-model="propPassword"
            :placeholder="getPlaceHolderPassword"
          />
        </div>
        <div class="login_area">
          <div v-if="cloudguestlogin === 'false' && !blank" class="help">
            <a
              v-if="world === '5'"
              class="grayText"
              href="/service/devk/PasswortVergessen"
              >Passwort vergessen?</a
            >
            <a
              v-if="world === '4'"
              class="grayText"
              href="https://email.freenet.de/service/frn/PasswortVergessen"
              >Passwort vergessen?</a
            >
            <a
              v-if="tenent === 'freenetplus'"
              class="grayText"
              href="https://email.freenet.de/service/frn/PasswortVergessen"
              >Passwort vergessen?</a
            >
            <a
              v-if="world === '2' && tenent !== 'freenetplus'"
              href="https://kundenservice.freenet.de/artikel/2015"
              >Brauchst Du Hilfe?</a
            >
          </div>

          <div class="login-button-container">
            <Button
              class="login"
              text="anmelden"
              href="#"
              v-on:click.native="doLogin"
            />
          </div>
        </div>
        <div v-if="world == 2">
          <div class="frn_loginBoxTrennerPasskey">
            <hr />
            <span>oder</span>
            <hr />
          </div>
        </div>

        <div v-if="world == 2" class="login-button-container">
          <IconButton
            icon="icon-only icon-help-passkey"
            href="https://kundenservice.freenet.de/artikel/6616"
          />
          <Button
            class="loginPasskey"
            text="Anmelden mit Passkey"
            href="#"
            light="true"
            v-on:click.native="doPasskeyLogin"
          />
        </div>
      </div>

      <div
        class="form-footer"
        v-if="showFormFooter === true && !blank"
        :class="world === '2' ? 'portal' : ''"
      >
        <div
          v-if="world === '5'"
          class="frn_loginBoxTrenner world_5"
          :class="world"
        ></div>
        <div class="frn_subLogin">
          <div v-if="world !== '5'" class="frn_subLoginLabel" :class="theme">
            freenet Mail & Cloud
            <ul>
              <li>E-Mail made in Germany</li>
              <li>Cloud mit 3 GB</li>
            </ul>
          </div>
          <br v-else />
        </div>
        <div class="frn_loginBoxRegister">
          <Button
            class="btn-reg"
            v-if="world === '5'"
            text="Neu registrieren?"
            href="/registrierung"
          />
          <Button
            v-else
            text="Kostenlos registrieren"
            href="https://email.freenet.de/registrierung/frn?epid=e9900000053&tid=2001004"
            target="_top"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import Button from "./Button";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import FrnApiClient from "@frnde/frn-api-client";
import IconButton from "./Iconbutton";

export default {
  data: function () {
    return {
      propLoginname: this.loginname,
      propPassword: this.password,
    };
  },
  components: {
    IconButton,
    Button,
  },

  computed: {
    showFormFooter() {
      // no footer for md
      if (this.tenent === "freenetplus") {
        return false;
      }
      if (this.world === "4") {
        return false;
      }
      if (this.cloudguestlogin === "true") {
        return false;
      }
      return true;
    },
    getPlaceHolderPassword() {
      let text = "Passwort";
      if (this.cloudguestlogin === "true") {
        text = "Zugangscode";
      }
      return text;
    },
    getTitle() {
      let title = "Login Mail & Cloud";
      switch (this.world) {
        case "5":
        case "4":
          title = "Login";
          break;
      }
      if (this.tenent === "freenetplus") {
        title = "Login";
      }
      // Text override for cloud guest login
      if (this.cloudguestlogin === "true") {
        title = "Zugangscode";
      }
      return title;
    },
  },

  mounted() {
    const mailInput = this.$refs.login;
    if (mailInput) {
      mailInput.focus();
    }

    // simplewebauthn browser plugin for passkey
    const script = document.createElement("script");
    script.src = "https://webmail.freenet.de/simplewebauthnbrowser.js";
    script.async = true;
    document.body.appendChild(script);
  },

  mixins: [validationMixin],

  props: {
    world: String,
    tenent: String,
    callbackurl: String,
    callback: String,
    callbackfn: Function,
    loginname: String,
    password: String,
    ignorelogoutforget: String,
    theme: String,
    cloudguestlogin: String,
    frnportalnoindex: Boolean,
    domainlogin: Boolean,
    blank: Boolean,
    redirect: String,
  },

  validations: {
    loginname: { required },
    password: { required },
  },

  methods: {
    doLogin: function () {
      let me = this;
      this.$v.$touch();
      if (this.$v.$error) return;

      this.checkCanLogin();

      FrnApiClient.Auth.login(
        this.loginname,
        this.password,
        this.world,
        this.ignorelogoutforget,
        this.domainlogin,
        this.redirect,
        this.callback
      )
        .then(function () {
          if (
            me.loginname.indexOf("@freenet.plus") !== -1 ||
            me.loginname.indexOf("@freenet.email") !== -1 ||
            me.loginname.indexOf("@freenet.digital") !== -1
          ) {
            switch (true) {
              case window.location.host.indexOf("test-") !== -1:
                top.location = "https://test-plus.freenet.de";
                return;
              case window.location.host.indexOf("stage-") !== -1:
                top.location = "https://stage-plus.freenet.de";
                return;
              default:
                top.location = "https://plus.freenet.de";
                return;
            }
          }
          if (me.redirect !== "false") {
            top.location = me.callbackurl || "https://webmail.freenet.de";
          }

          if (me.callbackfn) {
            if (typeof me.callbackfn === "function") {
              try {
                me.callbackfn(); // Sicherer Aufruf
              } catch (error) {
                console.error("Fehler im Callback:", error);
              }
            }
          }
        })
        .catch(function (error) {
          me.$emit("errorMessage", error.message);
        });
    },
    doPasskeyLogin: async function () {
      const me = this;
      const authenticationOptions =
        await FrnApiClient.Auth.fetchPasskeyAuthOpts();
      const challengeID = authenticationOptions.challengeID;
      /* eslint-disable */
      const assertResponse = await SimpleWebAuthnBrowser.startAuthentication(
          authenticationOptions
      );

      assertResponse["challengeID"] = challengeID;

      await FrnApiClient.Auth.loginByPasskey(
          assertResponse,
          this.world,
      ).then(function (response) {
        const loginname = response.username;

        if (
            loginname.indexOf("@freenet.plus") !== -1 ||
            loginname.indexOf("@freenet.email") !== -1 ||
            loginname.indexOf("@freenet.digital") !== -1
        ) {
          switch (true) {
            case window.location.host.indexOf("test-") !== -1:
              top.location = "https://test-plus.freenet.de";
              return;
            case window.location.host.indexOf("stage-") !== -1:
              top.location = "https://stage-plus.freenet.de";
              return;
            default:
              top.location = "https://plus.freenet.de";
              return;
          }
        }

        if (me.redirect) {
          top.location = me.callbackurl || "https://webmail.freenet.de";
        }

      })
          .catch(function (error) {
            me.$emit("errorMessage", error.message);
          });
    },
    checkCanLogin: function () {
      const host = window.location.hostname;

      switch (host) {
        case "local-plus.freenet.de":
        case "plus.freenet.de":
        case "test-plus.freenet.de":
        case "stage-plus.freenet.de":
          this.checkFreenetPlus(host);
          break;
        case "local.freenetcloud.de":
        case "freenetcloud.de":
        case "test.freenetcloud.de":
        case "stage.freenetcloud.de":
          this.checlFreenetcloud(host);
          break;
        default:
          return true;
      }
    },
    checkFreenetPlus: function (host) {
      if (
          this.world === "2" &&
          host.indexOf("plus.freenet.de") !== -1 &&
          (this.loginname.indexOf("@freenet.plus") !== -1 ||
              this.loginname.indexOf("@freenet.email") !== -1 ||
              this.loginname.indexOf("@freenet.digital") !== -1)
      ) {
        return true;
      } else {
        this.redirectTo();
      }
    },
    checlFreenetcloud: function (host) {
      if (this.world === "4" && host.indexOf("freenetcloud.de") !== -1) {
        return true;
      } else {
        this.redirectTo();
      }
    },
    redirectTo() {
      switch (true) {
        case this.loginname.indexOf("@freenet.de") !== -1:
          window.location.href = "https://www.freenet.de";
          break;
        case this.loginname.indexOf("@freenet.plus") !== -1:
        case this.loginname.indexOf("@freenet.email") !== -1:
        case this.loginname.indexOf("@freenet.digital") !== -1:
          window.location.href = "https://plus.freenet.de/login";
          break;
        case this.loginname.indexOf("@freenetcloud.de") !== -1:
          window.location.href = "https://www.freenetcloud.de/login";
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../scss/colors";

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: gray;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: gray;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: gray;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: gray;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: gray;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: gray;
}

#frn-loginbox {
  .frn-white {
    .frn_loginBoxTrennerPasskey {
      span {
        color: $frn-dark-blue !important;
      }
    }
  }

  .login-button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .loginPasskey {
      margin-bottom: 8px;
    }
  }

  .frn_loginBoxRegister {
    margin: 0 auto;
    text-align: center;
  }

  a {
    text-decoration: none;
    font-size: 1em;
    color: $frn-white !important;
  }

  .frn_loginBoxTrenner {
    border-bottom: solid 2px #c2de9a;
    border-top: none;
  }

  .frn_loginBoxTrennerPasskey {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 8px 0;

    hr {
      flex-grow: 1;
      border: none;
      border-top: 1px solid #628C27;
    }

    span {
      padding: 0 6px;
      color: $frn-white;
    }
  }

  input {
    width: 100%;
    height: 29px;
    float: none;
    margin-top: 0px;
    margin-bottom: 1px;
    padding: 2px 0 0 15px;
    border: solid 1px $frn-white;
    font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
    color: #333;
    font-size: 1.3em;
    overflow: hidden;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    line-height: normal !important;
  }

  .frn-white {
    input {
      background-color: $input-bg-color !important;
    }
  }

  .hasError {
    border: 1px solid #e20001;
  }

  .login-main {
    padding: 9px 20px;

    .login-logo {
      margin-bottom: 18px;
      color: $frn-white;
      font-size: 22px;

      p {
        margin: 0;
      }
    }

    .login-logo.frn-white {
      color: $black-text;
    }

    .login_area {
      display: flex;
      justify-content: space-between;
      padding: 11px 0 0 0;

      .help {
        a {
          font-size: 0.75rem;
          line-height: 1.4rem;
        }
      }

      div {
        width: 50%;

        &:only-child {
          width: 100%;
        }
      }
    }

    &.world_4 {
      padding-bottom: 20px;
    }
  }

  .form-footer {
    padding: 0 20px 9px 20px;

    &.portal {
      background: $frn-portal-info;

      .frn_button {
        background-color: $frn-green;
      }
    }

    .frn_subLogin {
      display: block;
      font-size: 11px;

      .frn_subLoginLabel {
        color: $frn-portal-info-text;
        font-size: 1.38rem;
        line-height: 1.6rem;
        padding: 15px 0 5px 0;
        font-weight: 600;

        ul {
          margin: 0;
          padding: 23px 0 2px 0;

          li {
            margin: 0 0 16px 0;
            padding: 0 0 0 50px;
            list-style: none;
            font-size: 1.13rem;
            line-height: 1.5rem;
            font-weight: 300;
            background-image: url("https://components.freenet.de/assets/Login/cloud.svg");
            background-repeat: no-repeat;

            &:first-of-type {
              background-image: url("https://components.freenet.de/assets/Login/mail.svg");
              background-position-x: 2px;
              background-size: 30px;
            }
          }
        }
      }

      .frn_subLoginLabel.frn-white {
        color: $gray-text !important;
      }
    }
  }

  .frnLoginSubmit {
    float: right;
    text-transform: uppercase;
    background: $frn-dark-blue;
    color: $frn-white;
    border: none;
    padding: 0 19px;
    cursor: pointer;
    outline: none;
    height: 30px;
    vertical-align: middle;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.6em;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    font-weight: 700;
  }

  .grayText {
    color: $gray-text !important;
  }

  .btn-reg {
    margin-top: 15px;
  }
}
</style>
